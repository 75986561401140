<!--门禁模块--访客信息管理-->
<template>
  <div id="visitorRecord" v-loading="loading" :element-loading-text="`${$t('visitorRecord.a1')}....`" >
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("visitorRecord.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="16">
          <!-- 自定义条件 -->
          <a-col :span="8">
            <a-input-group compact>
              <!-- 条件选择 -->
              <a-select v-model="form.condition1" :placeholder="$t('visitorRecord.a3')" style="width: 45%">
                <a-select-option v-for="item in conditions1" :key="item.key"> {{ item.title }} </a-select-option>
              </a-select>
              <!-- 输入条件 -->
              <a-input v-model="form.value1" :placeholder="queryCriteria" style="width: 55%"/>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="inquire"> {{ $t("visitorRecord.a8") }} </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" v-table-size="tableSize">
      <!-- 表格 -->
      <a-table :columns="columns" :dataSource="list" :pagination="pagination" :scroll="size" :rowKey="(record) => record.key">
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <!-- 删除按钮 -->
                <a-button class="table_button" type="primary" @click="() => onDeleteVisitor(record)" style="margin-right:15px">{{ $t("visitorRecord.a6") }}</a-button>
                <!-- 修改按钮 -->
                <a-button class="table_button" type="primary" @click="() => onEditVisitor(record)" >{{ $t("visitorRecord.a5") }}</a-button>
              </template>
              <a>{{ $t("visitorRecord.a7") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 底部功能栏 -->
    <div class="footer">
        <a-button type="primary" @click="onAddVisitor">{{$t('visitorRecord.a4')}}</a-button>
    </div>
    
    <!-- 添加/修改对话框 -->
    <a-modal :title="title" centered :width="800" :visible="visible" @ok="handleOk" @cancel="handleCancel" :destroyOnClose="true" v-drag>
      <my-visitorEditModal ref="modal" :editVisitor="editVisitor" :loading="visitorEditModal_loading"></my-visitorEditModal>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle"
import headerTitle from "../../components/Title/headerTitle"
import visitorEditModal from "./visitorEditModal"
import { getVisitorList,deleteVisitorPersonInfo,updateVisitorPersonInfo,insertVisitorPersonInfo } from "../../api/visitor"
import moment from "moment";

export default {
  name: "visitorRecord",
  data() {
    return {
      title:this.$t('visitorRecord.a4'),
      isNew: false,
      editVisitor:{},
      visible: false,
      endOpen: false,
      form:{
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
        value1: '',
        condition1: 'visitorName'       
      },
      conditions1:[
        {key:"visitorName",title:this.$t("visitorRecord.a13")},
        {key:"visitorPhone",title:this.$t("visitorRecord.a14")},
        {key:"visitorTelPhone",title:this.$t("visitorRecord.a20")},
        {key:"visitorCompany",title:this.$t("visitorRecord.a17")},
        {key:"visitorDept",title:this.$t("visitorRecord.a18")},
        {key:"visitorPosition",title:this.$t("visitorRecord.a19")}
      ],
      loading: false,
      visitorEditModal_loading: false,
      size: {  x: 2500,y: 240 },
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("visitorRecord.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          fixed: 'left' ,
          align: 'center',
          width: 60
        },        
        {
          title: this.$t("visitorRecord.a13"),
          dataIndex: "visitorName",
          fixed: 'left' ,
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorRecord.a12"),
          dataIndex: "visitorTypeName",
          align: 'center',
          width: 150
        },
        {
          title: this.$t("visitorRecord.a14"),
          dataIndex: "visitorPhone",
          align: 'center',
          width: 150
        },
        {
          title: this.$t("visitorRecord.a15"),
          dataIndex: "certificateTypeName",
          align: 'center',
          width: 150
        },
        {
          title: this.$t("visitorRecord.a16"),
          dataIndex: "certificateNo",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorRecord.a17"),
          dataIndex: "visitorCompany",
          align: 'center',
        },
        {
          title: this.$t("visitorRecord.a18"),
          dataIndex: "visitorDept",
          align: 'center',
        },
        {
          title: this.$t("visitorRecord.a19"),
          dataIndex: "visitorPosition",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorRecord.a20"),
          dataIndex: "visitorTelPhone",
          align: 'center',
          width: 200
        },
         {
          title: this.$t("visitorRecord.a21"),
          dataIndex: "visitorWechat",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorRecord.a22"),
          dataIndex: "visitorAddress",
          align: 'center',
          width: 200
        },        
        {
          title: this.$t("visitorRecord.a23"),
          dataIndex: "dateTime",
          align: 'center',

        },
        {
          title: this.$t("visitorRecord.a7"),
          dataIndex: "chaozuo",
          fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
        },
      ],
    };
  },
  computed:{
    // 查询条件输入框占位符
    queryCriteria: function (){
      let conditions1 = this.form.condition1
      if(conditions1 === 'visitorName'){ return this.$t("visitorRecord.a13") }
        else 
      if(conditions1 === 'visitorPhone'){ return this.$t("visitorRecord.a14") }
        else 
      if(conditions1 === 'visitorTelPhone'){ return this.$t("visitorRecord.a20") }
        else 
      if(conditions1 === 'visitorCompany'){ return this.$t("visitorRecord.a17") }
        else 
      if(conditions1 === 'visitorDept'){ return this.$t("visitorRecord.a18") }
        else 
      if(conditions1 === 'visitorPosition'){ return tthis.$t("visitorRecord.a19") }
        else
      { return ''}
    }
  },
  methods: {
    moment,
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById('visitorRecord')
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName('main')[0].clientHeight
        const title = indexDom.getElementsByClassName('ant-table-thead')[0].clientHeight
        const tableHeight = height - 64 - title
        this.size.y = tableHeight
      }
    },
    // 添加访客记录
    onAddVisitor() {   
      this.title=this.$t('visitorRecord.a4'),
      this.editVisitor = {
                certificateNo: "",
                certificateType: 0,
                id: 0,
                memo: "",
                tenantId: 0,
                visitorAddress: "",
                visitorCompany: "",
                visitorDept: "",
                visitorName: "",
                visitorPhone: "",
                visitorPosition: "",
                visitorTelPhone: "",
                visitorType: 0,
                visitorWechat: "",
      }
      this.isNew = true;
      this.visible = true;
    },
    // 修改访客记录
    onEditVisitor(record) {
      this.title = this.$t('visitorRecord.a5')
      const _editVisitor = JSON.stringify(record)
      const editVisitor = JSON.parse(_editVisitor)
      this.editVisitor = editVisitor
      this.isNew = false
      this.visible = true
    },
    // 删除访客记录
    onDeleteVisitor(record) {
      console.log("insertVisitorPersonInfo siteId:",this.$route.query.id)
      console.log("insertVisitorPersonInfo personInfoId:",record.id)
      this.$confirm({
        title: this.$t('visitorBlacklist.a20'),
        content: record.visitorName,
        centered: true,
        onOk:()=> {
          this.loading = true
          deleteVisitorPersonInfo(this.$route.query.id,record.id)
          .then((res) => {              
            this.loading = false
            let { data, errorCode, msg } = res
            if(errorCode === '00' || errorCode === '05'){
              if(data){
                this.list = this.list.filter(item => { return item.id != record.id })
                this.$message.success(msg)
              }else{
                this.$message.error(msg)
              }
            }else{
              this.$message.error(msg)
            }
            console.log("deleteVisitorPersonInfo res:",data)
          })
          .catch((err) => {
            this.loading = false
            this.$message.error(this.$t('visitorRecord.a26'))
            console.log(err)
          })
        }
      })
    },
    // 访客信息添加
    handleOk(){
      this.$refs.modal.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.visitorEditModal_loading = true
          let par = {
                    certificateNo: this.editVisitor.certificateNo,
                    certificateType: this.editVisitor.certificateType,
                    id: this.editVisitor.id,
                    memo: this.editVisitor.memo,
                    tenantId: this.editVisitor.tenantId,
                    visitorAddress: this.editVisitor.visitorAddress,
                    visitorCompany: this.editVisitor.visitorCompany,
                    visitorDept: this.editVisitor.visitorDept,
                    visitorName: this.editVisitor.visitorName,
                    visitorPhone: this.editVisitor.visitorPhone,
                    visitorPosition: this.editVisitor.visitorPosition,
                    visitorTelPhone: this.editVisitor.visitorTelPhone,
                    visitorType: this.editVisitor.visitorType,
                    visitorWechat: this.editVisitor.visitorWechat
                  }
          console.log("insertVisitorPersonInfo siteId:",this.$route.query.id)
          console.log("insertVisitorPersonInfo par:",par)
          // isNew: true为添加信息，false为修改信息
          if(this.isNew){
            // 添加信息
            insertVisitorPersonInfo(this.$route.query.id,par)
              .then((res) => {      
                let { data, errorCode, msg} = res
                if(errorCode === '00'){
                  this.$message.success(msg)
                  data.key = this.list.length
                  this.list.push(data)
                  this.visible = false
                }else{
                  this.$message.error(msg)
                }
                this.visitorEditModal_loading = false
                console.log("insertVisitorPersonInfo res:",data)
              })
              .catch((err) => {
                this.$message.error(this.$t('visitorRecord.a26')) 
                this.visitorEditModal_loading = false
                console.log(err)
              })
              
          }
          else{
            // 修改信息
            updateVisitorPersonInfo(this.$route.query.id,par)
              .then((res) => {              
                let { data, errorCode, msg } = res
                if(errorCode === '00'){
                  const editVisitor = this.editVisitor
                  this.list = this.list.map(item => {
                    if(item.id === editVisitor.id){
                      return editVisitor
                    }else{
                      return item
                    }
                  })
                  this.$message.success(msg)
                  this.visible = false
                }else{
                  this.$message.error(msg)
                }
                this.visitorEditModal_loading = false
                console.log("updateVisitorPersonInfo res:",data)
              })
              .catch((err) => {
                this.visitorEditModal_loading = false
                this.$message.error(this.$t('visitorRecord.a26'))
                console.log(err);
              });
          }
        } else {
          return false;
        }
      }); 
      
    },
    // 关闭添加/访客信息管理对话框
    handleCancel(){
      this.visible = false;
    },
    
    // 条件查询方法
    inquire() {
      this.loading = true;
      let par = {
        certificateNo: '',
        certificateType: 0,
        id: 0,
        memo: '',
        tenantId: 0,
        visitorAddress: '',
        visitorType: '',
        visitorWechat: '',
        visitorName: this.form.condition1 == "visitorName" ? this.form.value1 : "",
        visitorTelPhone: this.form.condition1 == "visitorTelPhone" ? this.form.value1 : "",
        visitorPhone: this.form.condition1 == "visitorPhone" ? this.form.value1 : "",
        visitorCompany: this.form.condition1 == "visitorCompany" ? this.form.value1 : "",
        visitorDept: this.form.condition1 == "visitorDept" ? this.form.value1 : "",
        visitorPosition: this.form.condition1 == "visitorPosition" ? this.form.value1 : ""
      }
      console.log("getVisitorList siteId:",this.$route.query.id)
      console.log("getVisitorList par:",par)
      getVisitorList(this.$route.query.id,par)
        .then((res) => {  
          let { data, errorCode } = res
          if(errorCode === '00'){
            for (let i = 0; i < data.length; i++) { data[i].key = i }
            this.list = data
          }else{
            return false
          }
          this.loading = false
          // console.log("getVisitorList res:",this.list)
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    }
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-visitorEditModal":visitorEditModal,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#visitorRecord {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 113px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 168px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 20px;
}
</style>