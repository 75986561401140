<!--访客模块--访客信息编辑窗口-->
<template>
  <div v-loading="loading" :element-loading-text="`${$t('visitorRecord.a1')}....`">
    <a-form-model ref="ruleForm" :model="editVisitor" layout='horizontal' :rules="rules"> 
      <a-row :gutter="32">
        <!-- 访客姓名 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a13')" prop="visitorName" >
            <a-input v-model="editVisitor.visitorName" :maxLength="maxLenName" allowClear/>
          </a-form-model-item>  
        </a-col>
        <!-- 访客手机号码 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a14')" prop="visitorPhone" >
            <a-input v-model="editVisitor.visitorPhone"  :maxLength="maxLenPhone" allowClear/>
          </a-form-model-item> 
        </a-col>
        <!-- 访客固定号码 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a20')" >
            <a-input v-model="editVisitor.visitorTelPhone"  :maxLength="maxLenName" allowClear/>
          </a-form-model-item>  
        </a-col>
        <!-- 访客单位/公司 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a17')" >
            <a-input v-model="editVisitor.visitorCompany"  :maxLength="maxLenName" allowClear/>
          </a-form-model-item> 
        </a-col>
        <!-- 访客所属部门 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a18')" >
            <a-input v-model="editVisitor.visitorDept"  :maxLength="maxLenName" allowClear/>
          </a-form-model-item>  
        </a-col>
        <!-- 访客职位 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a19')" >
            <a-input v-model="editVisitor.visitorPosition"  :maxLength="maxLenName" allowClear/>
          </a-form-model-item>
        </a-col>
        <!-- 访客证件类型 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a15')" >
              <a-select v-model="editVisitor.certificateType">
                <a-select-option v-for="item in certificateTypes" :key="item.key"> {{ item.title }} </a-select-option>
              </a-select>
          </a-form-model-item> 
        </a-col>
        <!-- 访客证件号 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a16')" >
            <a-input :disabled="editVisitor.certificateType == 0" v-model="editVisitor.certificateNo" :maxLength="maxLenName" allowClear/>
          </a-form-model-item>
        </a-col>
        <!-- 访客类型 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a12')" >
            <a-select v-model="editVisitor.visitorType">
              <a-select-option v-for="item in visitorTypes" :key="item.key"> {{ item.title }} </a-select-option>
            </a-select>
          </a-form-model-item> 
        </a-col>
        <!-- 访客地址 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a22')" >
            <a-input v-model="editVisitor.visitorAddress" :maxLength="maxLenName" allowClear/>
          </a-form-model-item>
        </a-col>
        <!-- 访客微信 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a21')" >
            <a-input v-model="editVisitor.visitorWechat" :maxLength="maxLenName" allowClear/>
          </a-form-model-item>
        </a-col>
        <!-- 备注 -->
        <a-col :span="8">
          <a-form-model-item :label="$t('visitorRecord.a24')">
            <a-input v-model="editVisitor.memo" :maxLength="maxLenName" allowClear/>
          </a-form-model-item> 
        </a-col>
      </a-row>
    </a-form-model> 
  </div>    
    
</template>

<script>
export default { 
  props: {
    editVisitor: {
      type: Object,
    },
    loading:{
      type:Boolean,
      default:false,
    }
  }, 
  data(){
    return {      
      maxLenName:32,
      maxLenPhone:11,
      certificateTypes:[
        {key:0,title:"无"},
        {key:1,title:"一代身份证"},
        {key:2,title:"二代身份证"},
        {key:3,title:"驾驶证"},
        {key:4,title:"军官证"},
        {key:5,title:"居住证"},
        {key:6,title:"护照"},
        {key:7,title:"回乡证"},
        {key:8,title:"港澳通行证"},
        {key:9,title:"台胞证"}
      ],
      visitorTypes:[
        {key:0,title:"无"},
        {key:1,title:"普通访客"},
        {key:2,title:"VIP访客"},
        {key:3,title:"特殊访客"},
        {key:4,title:"其他访客"}
      ],
      rules: {
        visitorName: [
          { required: true, message: this.$t('visitorRecord.a27'), trigger: 'change' }
        ],
        visitorPhone: [
          { required: true, message: this.$t('visitorRecord.a28'), trigger: 'change' }
        ]
      }                          
    }
  },
  methods: {    
  },
  components: {
    // "my-password":passwordModal,
  },
  
};
</script>

<style scoped>
</style>